<template>
  <div>
    <chart v-for="(item, index) in chartList" :key="index" :chartName="item.name" :chartType="item.type" :chartStyle="item.style" :chartData="item.data" @click="chartClick"></chart>
    <!-- <div class="absolute" :style="g6Style">
      <l-antv-g6></l-antv-g6>
    </div> -->
    <rb-list v-if="!config" :config="config" :monitorData="monitorData"></rb-list>
  </div>
</template>

<script>
import Chart from './chart'
import RBList from './rb_list'
// import LAntvG6 from '@/lgh/components/antv/G6'
export default {
  props: {
    monitorData: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: null
    }
  },
  components: { chart: Chart, 'rb-list': RBList },
  data() {
    return {
      chartList: [],
      g6Style: null
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    monitorData() {
      this.chartList = []
      setTimeout(() => {
        this.init()
      }, 50)
    }
  },
  methods: {
    init() {
      // 左1
      this.chartList.push({
        name: 'adviceTypeData',
        type: 'pie',
        style: {
          left: this.config.space + 'px',
          top: this.config.space + 'px',
          height: this.config.unit + 'px',
          width: this.config.unit + 'px'
        },
        data: {
          title: '建议类型',
          pie: {
            radius: '50%'
            // roseType: 'area'
          },
          data: this.monitorData.adviceTypeData
        }
      })
      // 左2
      this.chartList.push({
        name: 'adviceCategoryData',
        type: 'pie',
        style: {
          left: this.config.space + 'px',
          top: this.config.unit + this.config.space * 2 + 'px',
          height: this.config.unit + 'px',
          width: this.config.unit + 'px'
        },
        data: {
          title: '建议分类',
          pie: {
            radius: ['20%', '55%']
            // roseType: 'area'
          },
          data: this.monitorData.adviceCategoryData
        }
      })
      // 右1
      this.chartList.push({
        name: 'adviceStatusData',
        type: 'pie',
        style: {
          right: this.config.space + 'px',
          top: this.config.space + 'px',
          height: this.config.unit + 'px',
          width: this.config.unit + 'px'
        },
        data: {
          title: '建议状态',
          color: ['rgb(0,103,187)', 'rgb(255,110,118)', 'rgb(43,130,29)'],
          pie: {
            radius: '50%',
            roseType: 'radius'
          },
          // data: [
          //   { name: '正常', value: 18 },
          //   { name: '即将到期', value: 4 },
          //   { name: '已过期', value: 1 }
          // ]
          data: this.monitorData.adviceStatusData
        }
      })
      // 右2
      this.chartList.push({
        name: 'effectStatusData',
        type: 'pie',
        style: {
          right: this.config.space + 'px',
          top: this.config.unit + this.config.space * 2 + 'px',
          height: this.config.unit + 'px',
          width: this.config.unit + 'px'
        },
        data: {
          title: '实施状态',
          color: ['rgb(153,153,153)', 'rgb(43,130,29)', 'rgb(230,182,0)', 'rgb(255,0,0)'],
          pie: {
            radius: ['20%', '55%'],
            roseType: 'radius'
          },
          data: this.monitorData.effectStatusData
        }
      })
      // 左3
      this.chartList.push({
        name: 'x_monthData',
        type: 'bar',
        style: {
          left: this.config.space + 'px',
          bottom: this.config.space + 'px',
          height: this.config.unit + 'px',
          width: (this.config.width - this.config.space * 3) / 2 + 'px'
        },
        data: {
          title: this.monitorData.year ? this.monitorData.year + '年统计' : '年份统计',
          color: ['rgb(0,103,187)', 'rgb(255,110,118)', 'rgb(232,142,37)', 'rgb(255,0,0)', 'rgb(230,182,0)', 'rgb(43,130,29)'],
          data: this.monitorData.x_monthData
        }
      })
      // 右3
      this.chartList.push({
        name: 'x_departData',
        type: 'bar',
        style: {
          right: this.config.space + 'px',
          bottom: this.config.space + 'px',
          height: this.config.unit + 'px',
          width: (this.config.width - this.config.space * 3) / 2 + 'px'
        },
        data: {
          title: this.monitorData.isEGroup ? '企业监控' : '部门监控',
          color: ['rgb(250,110,118)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
          data: this.monitorData.x_departData
        }
      })

      // 中间
      this.chartList.push({
        name: '123123',
        type: 'bar',
        style: {
          top: this.config.head.height + this.config.space * 2 + 'px',
          left: this.config.unit + this.config.space * 2 + 'px',
          height: this.config.height - this.config.head.height - this.config.unit - this.config.space * 4 + 'px',
          width: this.config.width - this.config.unit * 2 - this.config.space * 4 + 'px'
        },
        data: {
          title: '好建议统计',
          color: ['rgb(43,130,29)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
          data: this.monitorData.c_departData
        }
      })

      // this.g6Style = {
      //   top: this.config.head.height + this.config.space * 2 + 'px',
      //   left: this.config.unit + this.config.space * 2 + 'px',
      //   height: this.config.height - this.config.head.height - this.config.unit - this.config.space * 4 + 'px',
      //   width: this.config.width - this.config.unit * 2 - this.config.space * 4 + 'px'
      // }
    },
    chartClick(a, b) {
      if (a === 'carNature') {
        this.$site.showCarList({ carNature: b.data.code })
      } else if (a === 'carStatus') {
        this.$site.showCarList({ carStatus: b.data.code })
      } else if (a === 'insurance') {
        if (b.seriesName.indexOf('交强险') !== -1) {
          this.$site.showCarList({ carStatus: 1, jqxInsuranceMonth: b.dataIndex + 1, noStatus7: true })
        } else if (b.seriesName.indexOf('商业险') !== -1) {
          this.$site.showCarList({ carStatus: 1, syxInsuranceMonth: b.dataIndex + 1, noStatus7: true })
        }
      } else if (a === 'yearlyInspect') {
        this.$site.showCarList({ carStatus: 1, yearlyInspectMonth: b.dataIndex + 1, noStatus7: true })
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
